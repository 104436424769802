
.company {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	// padding-left: 44px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

	// &::before {
	// 	position: absolute;
	// 	left: 0; top: 4px;
	// 	width: 26px;
	// 	height: 26px;
	// 	content: '';
	// 	border-radius: 50%;
	// 	background: rgba(0,0,0,.1);
	// }

	&__pic {
		
	}

	&__link {
		color: inherit;
		font-size: 22px;
		font-weight: bold;
		text-decoration: none;
	}

	&__info {
		font-size: 12px;

		p {
			margin: 0;

			strong {
				color: #42348d;
			}
		}
	}
}
