@import url("normalize.less");
@import url("fonts.less");
@import url("mixins.less");

@import url("slick.less");
@import url("slick-theme.less");
@import url("iziModal.min.less");


/* * * * * * * * * * *
*                    *
* Variables          *
*                    *
* * * * * * * * * * */

@fiolet: #42348d;
@red: #f11a3b;


// components
@import url("../components/header/style.less");
@import url("../components/nav/style.less");
@import url("../components/news/style.less");
@import url("../components/pagination/style.less");
@import url("../components/company/style.less");
@import url("../components/social/style.less");
@import url("../components/footer/style.less");


/* * * * * * * * * * *
*                    *
* Global             *
* Styles             *
*                    *
* * * * * * * * * * */
html,
body {
	width: 100%;
	min-height: 100%;
}

body {
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-moz-font-feature-settings: 'liga', 'kern';
	color: #454545;

	* {
		box-sizing: border-box;
	}
}

.page {
	&__wrapper {
		padding: 50px 0;
		display:flex;
		justify-content: space-between;
	}

	&__main {
		width: 66%;
		max-width: 690px;

		.content {
			padding-right: 20px;
		}
	}

	&__side {
		width: 32%;
		max-width: 330px;
	}
}

.container {
	width: 1100px;
	max-width: 100%;
	padding-left: 25px;
	padding-right: 25px;
	margin: 0 auto;
}

.banner {
	display: block;
	width: 300px;
	height: 300px;
	max-width: 100%;
	max-height: 100%;
	background: #d1d1d1;
	margin-bottom: 30px;
}

.banner_wide {
	width: 100%;
}

.field {
	display: block;
	margin-bottom: 20px;

	&__name {
		display: block;
		font-size: 12px;
		color: @fiolet;
		margin: 0 10px 10px;
	}

	&__input {
		display: inline-block;
		border: 1px solid @fiolet;
		border-radius: 16px;
		width: 240px;
		height: 33px;
		max-width: 100%;

		input {
			width: 100%;
			height: 100%;
			border: none;
			background: none;
			padding: 0 20px;
			box-sizing: border-box;

			&:focus {
				outline: none;
			}
		}

		textarea {
			border: none;
			resize: none;
			background: none;
			padding: 10px 20px;
			width: 100%;
			height: 100%;

			&:focus {
				outline: none;
			}
		}
	}
}

.field_wide {
	width: 100%;

	.field {
		&__input {
			width: 100%;
		}
	}
}

.field_small {
	width: 150px;
}

.field_area {
	.field {
		&__input {
			height: 120px;
		}
	}
}

.field_search {
	color: @fiolet;

	.field {
		&__input {
			background: url('../img/icons/search.png') no-repeat left center;
			background-position-x: 10px;

			input {
				padding-left: 45px;

				&::placeholder {
					color: inherit;
				}
			}
		}
	}
}

.side {
	&__caption {
		display: block;
		margin: 5px 0 25px;
		font-size: 20px;
		text-transform: uppercase;
		color: @fiolet;
		font-weight: bold;
		text-align: left;
	}

	&__block {
		margin: 30px 0;
		text-align: center;
	}
}

.events {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: left;

	&__item {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #f7f7e5;
		padding: 10px 0;
		margin-bottom: 5px;

		&:last-child {
			margin-bottom: 0;
		}

		&:hover {
			background: #f0eecc;

			span {
				font-weight: normal;
			}
		}

		a {
			position: absolute;
			left: 0; top: 0; right: 0; bottom: 0;
		}

		span {
			padding: 0 15px;
			font-size: 18px;
			color: #b2ac00;
			font-weight: bold;
		}

		p {
			padding: 0 20px 0 5px;
			margin: 0;
			color: #454545;
		}
	}
}

.link {
	color: @fiolet;

	&:hover {
		text-decoration: none;
	}
}

.link_red {
	color: @red;
}

.btn {
	display: inline-block;
	color: white;
	background: #b2ac00;
	text-decoration: none;
	text-align: center;
	padding: 0 24px;
	height: 33px;
	line-height: 30px;
	border-radius: 25px;
	border: none;

	&:focus {
		outline: none;
	}
}

.caption {
	display: block;
	font-size: 24px;
	color: @fiolet;
	margin-bottom: 20px;

	strong {
		display: block;
		font-size: 30px;
		color: #454545;
	}
}

.title {
	font-size: 30px;
	margin-bottom: 15px;
}

.content {
	margin: 15px 0;
	line-height: 1.571;

	h3 {
		font-size: 24px;
		font-weight: normal;
	}

	p {
		margin: 0 0 25px;
	}

	a {
		color: @fiolet;

		&:hover {
			text-decoration: none;
		}
	}

	& > img {
		display: block;
		margin: 10px auto;
		max-width: 100%;
	}

	& > ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			position: relative;
			margin: 20px 0;
			display: block;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&::before {
				display: inline-block;
				content: '-';
			}
		}
	}
}

.gallery {
	margin: 40px 0;

	&__for {
		max-width: 100%;
		min-height: 240px;
		width: 690px;
		max-height: 460px;
		height: 42vw;
		overflow: hidden;
		margin: 0 auto;
		padding: 0;
		list-style: none;

		img {
			max-width: 100%;
		}
	}

	&__nav {
		margin: 10px 0;
		padding: 0;
		list-style: none;

		.slick-slide {
			max-width: 157px;
			// width: 10.89vw;
			// height: 10vw;
			width: 157px;
			height: 100px;
			max-height: 100px;
			overflow: hidden;
			margin: 0 7px 0 8px;
			cursor: pointer;
			border: 1px solid transparent;
			font-size: 0;

			&.slick-choose {
				border-color: @fiolet;
				pointer-events: none;
			}

			&:focus {
				outline: none;
			}

			img {
				display: inline-block;
				min-height: 100%;
				min-width: 100%;
			}
		}
	}

	.slick-list {
		padding: 0 !important;
	}
}

.form {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	max-width: 100%;
	margin-bottom: 20px;

	&__main {
		display: flex;
		flex-wrap: wrap;
		width: 65%;
		max-width: 420px;
		justify-content: space-between;
	}

	&__side {
		width: 36%;
		max-width: 230px;
		padding-left: 30px;
	}
}

.form_register {
	width: 400px;

	.form {
		&__captcha {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}
	}

	.btn {
		margin-top: 30px;
	}
}

.comments {
	margin-bottom: 50px;
	padding: 30px 20px;
	border-top: 1px solid #b2ac00;

	&__title {
		display: block;
		font-size: 24px;
		color: #b2ac00;
		margin: 0 0 30px 10px;
	}
}

.modal {
	display: none;
	max-width: 100%;

	&__wrap {
		padding: 20px;
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 15px;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		background: none;
		border: none;
		padding: 0;

		&:focus {
			outline: none;
		}

		&::before {
			content: '×'
		}
	}
}

.modal_small {
	width: 360px;
}

.catalog {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 0;
	padding: 0;
	list-style: none;

	& > a {
		display: block;
		margin: 10px 0;
		font-size: 18px;
		color: inherit;

		&:hover {
			text-decoration: none;
		}

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.catalog_company {
	padding: 20px 0;
	border-top: 1px dashed currentColor;
}

.menu {
	display: none;
	position: absolute;
	z-index: 10;
	right: 15px;
	top: 25px;
	width: 30px;
	height: 20px;
	border-top: 4px solid @fiolet;
	border-bottom: 4px solid @fiolet;
	border: none;
	border-radius: 2px;
	background: none;
	transition: all .3s;
	padding: 0;

	&::before,
	&::after {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		content: '';
		width: 100%;
		height: 4px;
		background: @fiolet;
		transition: all .3s;
	}

	&:focus {
		outline: none;
	}

	&:active {
		height: 0;
	}
}

.menu_open {
	border: none;

	&::before {
		transform: translateY(-50%) rotate(45deg);
	}

	&::after {
		transform: translateY(-50%) rotate(-45deg);
	}

	& + .header__nav {
		display: block;
		top: 0;
		opacity: 1;
	}
}

.companys {
	display: flex;
	flex-wrap: wrap;
	margin: 0 0 20px;
	padding: 0;
	list-style: none;
	font-size: 0;

	li {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 10px 0;
		height: 80px;
		width: 50%;
		overflow: hidden;

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}

	a {
		text-decoration: none;

		&:focus {
			outline: none;
		}
	}
}

.slick-dots {
	li:first-child:last-child {
		display: none;
	}
}

.slick-arrow {
	&::before {
		display: block;
		font-size: 0;
		width: 8px;
		height: 8px;
		border-left: 2px solid black;
		border-bottom: 2px solid black;
		border-radius: 0 0 0 1px;
		opacity: 1;
		font-size: 0;
		content: '';
	}

	&.slick-prev {
		&::before {
			transform: rotate(45deg);
			margin-left: 8px;
		}
	}

	&.slick-next {
		&::before {
			transform: rotate(-135deg);
			margin-right: 8px;
		}
	}
}

/* * * * * * * * * * *
 * 									 *
 * Pages             *
 * Styles            *
 *                   *
 * * * * * * * * * * */


/* * * * * * * * * * *
* 									 *
* Block              *
* Styles             *
*                    *
* * * * * * * * * * */


/* * * * * * * * * * *
* 									 *
* Media              *
* Queries            *
*                    *
* * * * * * * * * * */

@import url("adaptive.less");
