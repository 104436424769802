.header {

  &__top {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px 0;
  }

  &__bottom {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__banner {
    width: 70%;
    max-width: 730px;
    height: 80px;
    max-width: 100%;
  }

  &__nav {
    width: 680px;
    margin-left: -25px;
    box-shadow: 0 5px 10px -5px rgba(0,0,0,.1);
  }
}

.logo {
  display: block;
  width: 200px;
  text-decoration: none;
  color: #42348d;
  font-size: 12px;

  p {
    margin: 0;
  }

  img {
    max-width: 100%;
  }
}

.search {
  // width: 23%;
  max-width: 240px;
  margin-right: 30px;
}
