.nav {
  display: flex;
  max-width: 100%;

  a {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    padding: 20px 0;
    flex: 1 1 auto;
    border-bottom: 3px solid transparent;
    text-decoration: none;
    color: black;
    text-align: center;

    &:hover {
      border-color: #40338c;
    }
  }
}
