@media (max-width: 1100px) {
  .form {
    &__main {
      width: 100%;
      max-width: 100%;
    }

    &__side {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }

    &__captcha {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .field_small {
      width: auto;
    }
  }
}

@media (max-width: 1000px) {
  .content {
    padding: 0;
  }

  .search {
    position: absolute;
    right: 0;
    bottom: 50px;
    margin: 0;
  }

  .header {
    &__nav {
      width: 100%;
      margin: 0;
    }
  }

  .page {
    &__wrapper {
      flex-wrap: wrap;
    }

    &__main {
      width: 100%;
      max-width: 100%;
    }

    &__side {
      // display: none;
      width: 100%;
      max-width: 100%;
    }
  }

  .side {

  }

  .n-item {
    width: auto;
  }

  .banner {
    width: 100%;
  }

  .links {
    li {
      &::after {
        margin: 0 2px 0 4px;
      }
    }
  }

  .footer {
    &__links {
      a {
        margin: 0 5px;
      }
    }
  }
}

// Mobile

@media (max-width: 768px) {
  .container {
    padding: 0 15px;
  }

  .page {
    &__wrapper {
      padding: 20px 0;
    }
  }

  .menu {
    display: block;
  }

  .nav {
    flex-direction: column;
  }

  .header {
    &__nav {
      padding-top: 40px;
      background: white;
      position: absolute;
      z-index: 9;
      left: 0;
      top: -100%;
      width: 100%;
      opacity: 0;
      transition: all .5s;
    }

    &__logo {
      width: 100%;
    }

    &__banner {
      width: 100%;
    }

    &__bottom {
      position: static;
    }

    &__top {
      padding: 15px 0;
    }
  }

  .n-item {
    margin-bottom: 20px;

    &__img {
      width: 140px;
      height: 100px;

      img {
        width: 100%;
      }
    }

    &__info {
      width: calc(~'100% - 150px');
    }

    &__info-line {
      margin: 5px 0;
    }

    &__title {
      font-size: 18px;
    }

    &__text {
      font-size: 12px;
    }
  }

  .n-item_big {
    .n-item {
      &__img {
        width: 200px;
        height: 140px;
      }

      &__info {
        width: calc(~'100% - 210px');
      }
    }
  }

  .logo {
    margin: 0 auto 10px;
    width: 120px;

    p {
      display: none;
    }
  }

  .search {
    position: static;
    margin: 0 auto;
  }

  .links {
    margin: 0 0 20px;
    width: 100%;
    text-align: center;
  }

  .footer {
    padding-bottom: 10px;

    &__container {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &__block {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      &:first-child {
        margin-bottom: 0;
      }
    }

    &__copy {
      width: 100%;
      text-align: center;

      br {
        display: none;
      }
    }
  }

  .gallery {
    &__nav {
      .slick-slide {
        width: 100px;
        height: 60px;
        margin-right: 6px;
      }
    }
  }
}

@media (max-width: 500px) {
  .n-item {
    flex-wrap: wrap;

    &__img {
      width: 100%;
      height: 50vw;
      margin-bottom: 10px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__title {
      display: block;
      font-size: 16px;
      text-align: center;
    }

    &__info-line {
      display: flex;
      justify-content: space-between;
      order: 5;
      margin-top: 10px;
    }

    &__comments {
      top: -6px;
    }
  }

  .n-item_big {
    .n-item {
      &__img {
        width: 100%;
        height: 50vw;
      }

      &__info {
        width: 100%;
      }
    }
  }

  .pagination {
    justify-content: center;
    flex-wrap: wrap;
  }

  .gallery {
    &__for {
      min-height: 200px;
    }
  }

  .comments {
    padding: 20px 0;

    .field {
      width: 100%;

      &__input {
        width: inherit;
      }
    }
  }

  .company {
    width: 100%;
    padding-left: 0;
    padding-bottom: 20px;
    border-bottom: 1px dashed currentColor;

    &:last-child {
      border: none;
    }

    &::before {
      display: none;
    }

    &__link {
      font-size: 20px;
    }
  }

  .field_search {
    text-align: center;

    .field {
      &__input {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .btn {
      width: 100%;
    }
  }
}
