.pagination {
  display: flex;
  align-items: center;
  margin: 50px 0 20px;
  padding: 0;
  list-style: none;

  &__item {
    position: relative;
    margin: 0 5px;
    color: white;
    line-height: 30px;
    border-radius: 50%;
    background: #b2ac00;
    border: 1px solid transparent;
    text-align: center;

    &:hover {
      opacity: .9;
    }

    a {
      display: block;
      width: 30px;
      height: 30px;
      color: inherit;
      text-decoration: none;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  &__item_active {
    pointer-events: none;
    background: white;
    border-color: currentColor;
    color: #b2ac00;
  }

  &__item_prev {
    transform: rotate(-45deg);

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30%;
      height: 30%;
      content: '';
      margin: 1px 0 0 1px;
      border-top: 2px solid white;
      border-left: 2px solid white;
      transform: translate(-50%,-50%);
    }
  }

  &__item_next {
    transform: rotate(-45deg);

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30%;
      height: 30%;
      content: '';
      margin: -1px 0 0 -1px;
      border-bottom: 2px solid white;
      border-right: 2px solid white;
      transform: translate(-50%,-50%);
    }
  }
}
