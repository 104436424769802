
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-bold-webfont.eot');
    src: url('../fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('../fonts/roboto-bold-webfont.woff') format('woff'),
         url('../fonts/roboto-bold-webfont.ttf') format('truetype'),
         url('../fonts/roboto-bold-webfont.svg#robotobold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-regular-webfont.eot');
    src: url('../fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto-regular-webfont.woff') format('woff'),
         url('../fonts/roboto-regular-webfont.ttf') format('truetype'),
         url('../fonts/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-italic-webfont.eot');
    src: url('../fonts/roboto-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-italic-webfont.woff2') format('woff2'),
         url('../fonts/roboto-italic-webfont.woff') format('woff'),
         url('../fonts/roboto-italic-webfont.ttf') format('truetype'),
         url('../fonts/roboto-italic-webfont.svg#robotoitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}
