.social {
  &__tabs {
    display: flex;
    align-items: flex-end;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      font-size: 30px;
      border-radius: 2px 2px 0 0;
      padding: 2px;
      background-color: white;
      cursor: pointer;
      border: 1px solid transparent;
      transition: opacity .3s;

      &:hover {
        opacity: .7;
      }

      &.active {
        border: 1px solid #d0d0d0;
        z-index: 3;
        border-bottom: none;
        pointer-events: none;

        .fa-facebook {
          color: #3b5998;
        }

        .fa-vk {
          color: #4c75a3;
        }

        .fa-odnoklassniki-square {
          color: #ed812b;
        }
      }

      svg {
        width: 100%;
        height: 100%;
        transition: all .3s;
      }

      .fa-vk {
        font-size: 23px;
      }
    }
  }

  &__body {
    position: relative;
    z-index: 1;
    margin-top: -1px;
    border: 1px solid #d0d0d0;
    border-radius: 2px;
    padding: 20px;
    background: white;
    min-height: 200px;
  }

  &__content {
    display: none;
  }
}
