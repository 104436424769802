.footer {
  position: relative;
  background: #f4f4f4;
  padding: 30px 0;

  &::before {
    position: absolute;
    left: 0; top: 0;
    width: 100%;
    height: 9px;
    content: '';
    box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__block {
    &:last-child {
      text-align: right;
    }

    .btn {
      margin: 0 20px;
    }
  }

  &__links {
    a {
      display: inline-block;
      text-decoration: none;
      margin: 0 15px;
    }
  }
}

.links {
  display: block;
  margin: 0 0 30px;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    display: inline-block;
    vertical-align: top;

    &::after {
      display: inline-block;
      content: '|';
      margin: 0 13px 0 18px;
    }

    &:last-child::after {
      display: none;
    }

    a {
      color: #272624;
      text-decoration: none;
    }
  }
}
