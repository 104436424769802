.n-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
  margin-bottom: 30px;
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }

  &__who {
    color: #42348d;
    font-weight: bold;
  }

  &__img {
    width: 240px;
    height: 170px;
    background: rgba(0,0,0,.03);
    overflow: hidden;

    img {
      min-width: 100%;
    }
  }

  &__info {
    width: calc(~'100% - 270px');
    padding-right: 5px;
  }

  &__title {
    font-size: 22px;
    text-decoration: none;
    color: #454545;

    &:hover {
      color: #42348d;
    }
  }

  &__info-line {
    display: block;
    margin: 10px 0;
  }

  &__comments {
    position: relative;
    display: inline-block;
    color: white;
    font-size: 10px;
    line-height: 21px;
    background: #f11a3b;
    border-radius: 3px;
    width: 21px;
    height: 21px;
    text-decoration: none;
    text-align: center;

    &::before {
      position: absolute;
      left: 0;
      top: 15px;
      width: 0;
      height: 0;
      content: '';
      border: 5px solid transparent;
      border-left-color: #f11a3b;
    }
  }

  &__text {
    margin: 0;
    font-size: 14px;
    color: rgb(120, 120, 120);
    line-height: 1.571;
  }
}

.n-item_big {
  margin-bottom: 50px;

  .n-item {
    &__img {
      width: 330px;
      height: 230px;
    }

    &__info {
      width: calc(~'100% - 360px');
    }
  }
}

.n-item_small {
  width: 244px;
  margin-bottom: 20px;

  &:hover {
    * {
      color: #42348d;
    }
  }

  .n-item {
    &__img {
      width: 60px;
      height: 60px;
    }

    &__info {
      width: calc(~'100% - 78px');
    }
  }

  a {
    position: absolute;
    left: 0; top: 0; right: 0; bottom: 0;
  }
}

.n-item_comment {
  width: 100%;
  pointer-events: none;

  .n-item {
    &__text {
      font-size: 12px;
      color: #333333;
      line-height: 1.2;
      margin-bottom: 5px;
    }
  }

  .date {
    font-size: 12px;
  }
}

.date {
  display: inline-block;
  margin-right: 16px;
  color: #b2ac00;
  font-style: italic;

  strong {
    color: #ec1c39;
    font-weight: normal;
  }
}
